import "../assets/stylesheets/styles.scss";
import "../assets/img/backgrounds/fond-niveau0.jpg"
import "../assets/img/svg/button-mobile.svg"
import "../assets/img/svg/button-desktop.svg"

const debug = false;

init();

function init() {
	console.log("Launch app");
}
